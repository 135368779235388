import {
    Drawer,Space,Divider, Skeleton, Row, Col, Cascader,
    Button, DatePicker, Radio,  Form, Input, Select, message, } from 'antd';
import React, { useContext, useEffect, useState } from "react"
import Helper from "../../func"
import moment from 'moment';
import './Editor.css';
import { Context } from '../Context';
//import SelectorField from '../fields/SelectorField';
import UploadAvatar from "./UploadAvatar";
import ComboBox from "../fields/ComboBox";
import {PlusCircleOutlined,MinusCircleOutlined} from "@ant-design/icons";
const { Option } = Select;

const defaultData={
    em_name:"",
    em_sex:2,
    em_birth:null,

    em_commencement_date:null,
    em_status:"",
    em_num:"",
    //em_org:"",
    //em_department:"",
    em_org_info:"",
    em_date_left:null,
    em_type:1,

    em_mobile:"",
    em_tel:"",
    em_email:"",
    u_account:'',
    u_id:0,
}

/**
 *
 * @param {number} props.emId
 * @param {string} props.title
 * @param {{}} props.drawerCfg 参考Drawer
 * @param {function} props.afterSaved
 * @returns {JSX.Element|*}
 * @constructor
 */

const EmployeeEditor = (props)=>{
    const _emId = props.emId||-1;
    //const form = Form.useFormInstance();
    const [form] = Form.useForm();
    const setting = useContext(Context)
    const orgOptions=setting.org_tree||{}

    const isM = Helper.isM();
    
    const hasPop = props.drawerCfg||false

    //const [status,setStatus]=useState({});
    const [loading,setLoading] = useState(true)
    const [submitting,setSubmitting] = useState(false)
    const [emName,setEmName] = useState("");
    const [extD,setExtD]=useState({});
    //const [formError,setFormError] = useState(false)   
    const [orgInfo, setOrgInfo]=useState([1]);

    const [emId,setEmId] = useState(_emId)
    const emOrgInfo = Form.useWatch('em_org_info', form);
    if(emId!=_emId){setEmId(_emId)}

    useEffect(()=>{_loadData()},[emId])

    useEffect(()=>{
       if(!hasPop){
           form.setFieldsValue({...defaultData})
       }
    },[hasPop])

    const _colSpan={xs:24,sm:24,md:24,lg:24,xl:12,className:"_col"};

    const _loadData=()=>{     
          if(emId<=0){
              console.log(1111)
              setLoading(false)
              form.setFieldsValue({...defaultData})
              setEmName(defaultData.em_name||'')
              setExtD({});
          }else{
              setLoading(true)
              Helper.http.get(Helper.serviceUrl('employee/load_data'),{em_id:emId},function(res){
              setLoading(false)

              res.result = res.result||{}
                  console.log('res.result----',res.result)
              const d = {...defaultData,...res.result};

              
              d.em_birth = dateToMoment(d.em_birth||null)
              d.em_date_left = dateToMoment(d.em_date_left||null)
              d.em_commencement_date = dateToMoment(d.em_commencement_date||null)
              setEmName(d.em_name)
              setExtD(d['_']||{})
                  console.log('d--回显--',d)
              // d.em_org_info = d.em_org_info.split('/');
                  let index_v=[]
                  let a = []
               d.em_org_info.forEach((v,index)=>{
                  index_v.push(index+1)
                  // if(d.em_org_info.length>1){
                      a.push(v.split('/'))
                  // }else {
                  //     a.push(v)
                  // }
              });
                  setOrgInfo(index_v)
                  let c= []
                  a.forEach((v)=>{
                      c.push(v)
                  })
                  const vl={...d,org_info:c}
                  console.log('vl-回显-',vl)
              setTimeout(()=>form.setFieldsValue(vl),300)

            });
          }
    }

    const dateToMoment=(value,defaultVal=null)=>{
      return (value||false)?moment(new Date(value)):defaultVal //.add(8, 'hour')
    }

    const porcessDate =(v)=>{
        return v? moment(v).format('YYYY-MM-DD'):''
    }

    const onClosePop=(e)=>{
        if(typeof props.drawerCfg.onClose){
            props.drawerCfg.onClose(e)
        }
    }

    const onValuesChange=(changedValues, allValues)=>{
      /*
      for(let k in changedValues){
        console.log(changedValues);
      }
      */
      //console.log(changedValues);
      //console.log(allValues);
        const error = form.getFieldsError();
        console.log(error)
    }


    const onFinish=(values)=>{
      console.log('搜索',values);
        let em_org_info =[]
        const org_info = values.org_info.map(item=>{
            console.log(11)
            if(Object.prototype.toString.call(item)!=='[object Array]'){
                item =item.split('/');
            }
            return item
        })
        console.log('org_info--',org_info)
        values = {...values,org_info}
        console.log('搜索222',values);
        values.org_info.forEach(v=>{
            em_org_info.push(v.join('/'))
        })

      const d ={...values,em_org_info}
        delete d['org_info'];
        
      d.em_birth = porcessDate(d.em_birth||null)
      d.em_date_left = porcessDate(d.em_date_left||null)
      d.em_commencement_date = porcessDate(d.em_commencement_date||null)
      // d.em_org_info = d.em_org_info.join('/');

      let url;
      if(emId>0){
        url =Helper.serviceUrl('employee/save')
        d['em_id'] = emId;
      }else{
        url =Helper.serviceUrl('employee/create')
        delete d['em_id'];
      }
      setSubmitting(true);
      Helper.http.post(url,d,
        (r)=>{
            setSubmitting(false);
            message.success('已保存')
            if(typeof props.afterSaved==='function'){
                props.afterSaved();
            }

        },
        (data,error)=>{
            setSubmitting(false);
            alert(data.message||error.message||'请求出错，请检查网络')
        }
      )
    }

    const onFinishFailed=()=>{
        
    }

    const onUploadAvatar=(res)=>{
        if(res.success && res.result.file_key){
            const fileKey = res.result.file_key||''
            const values = form.getFieldsValue()
            form.setFieldsValue({...values,...{em_avatar:fileKey}})
        }
    }

    // 添加组件
    const add = ()=>{
        setOrgInfo([...orgInfo,orgInfo.length+1])
    }
    // 移除指定索引的组件
    const remove = (index) => {
        if (orgInfo.length > 1) {
            setOrgInfo(orgInfo.filter((_, i) => i !== index));
        }
    };

    
    const formx=()=>{
        return <>
          <Form
            className='xx_employee_editor'
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            form={form}
            //ref={formRef}
            validateTrigger="onChange"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            autoComplete="off"
            initialValues={{}}
          >
              <Row>
                  <Col {...{className:"_col"}}>
                      <div className='_col_cnt'>
                          <UploadAvatar src={extD.avatar_url?extD.avatar_url:'./imgs/avatar-1.png'} url={Helper.serviceUrl('employee/upload_avatar')} afterUpload={ onUploadAvatar }></UploadAvatar>
                          <Form.Item name="em_avatar" hidden={true}>
                              <input/>
                          </Form.Item>
                      </div>
                  </Col>
                  <Col className="_col" style={{flexGrow: 1,display:"flex",overflow:"hidden"}}>
                      <div className="_col_cnt" style={{padding:"20px",width:"100%",fontSize:"20px"}}>{emName}</div>
                  </Col>
              </Row>
            <Row>
              <Col {..._colSpan}>
                <div className='_col_cnt'>
                  <Skeleton loading={loading} active >
                    <Form.Item label="姓名" name="em_name" rules={[{ required: true, message: '请输入姓名!' }]}>
                      <Input onChange={(e)=>setEmName(e.target.value||'')}/>
                    </Form.Item>

                    <Form.Item name="em_sex" label="性别">
                      <Radio.Group>
                        <Radio value={1}>男士</Radio>
                        <Radio value={2}>女士</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item name="em_birth" label="出生年月">
                      <DatePicker format="YYYY-MM-DD" style={{width:"100%"}}/>{/* */}
                    </Form.Item>
                  </Skeleton>
                </div>
              </Col>
              <Col {..._colSpan}>

                <div className='_col_cnt'>
                  <Skeleton loading={loading} active >
                    <Form.Item label="邮箱" name="em_email" rules={[{ required: true, message: '请输入邮箱！' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="手机号" name="em_mobile"  rules={[{ required: true, message: '请输入手机号！' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="座机" name="em_tel">
                      <Input />
                    </Form.Item>
                  </Skeleton>
                </div>

              </Col>

              <Col {..._colSpan}>
                <div className='_col_cnt'>
                  <Skeleton loading={loading} active >
                    {/*<Form.Item label="组织架构" validateStatus={emOrgInfo?'success':'error'} name="em_org_info"  rules={[{ required: true, message: '' }]}>*/}
                      {/*<Cascader options={orgOptions} placeholder="Please select"/>*/}
                      {/*</Form.Item>*/}
                      <Form.Item label="组织架构" validateStatus={emOrgInfo?'success':'error'}  rules={[{ required: true, message: '' }]}>
                          {
                              orgInfo.map((v,index)=>(
                                  <div key={v} style={{width: '100%',display:'flex'}}>
                                      <Form.Item style={{width:'100%'}} name={['org_info', index]}  rules={[{ required: true, message: '' }]} >
                                          <Cascader options={orgOptions} placeholder="Please select"/>
                                      </Form.Item>
                                      <div className='_org_info'>
                                          {/* 只有最后一个组件才显示添加按钮 */}
                                          {index === orgInfo.length - 1 && (
                                              <Button type='text' icon={<PlusCircleOutlined />} onClick={add}></Button>
                                          )}
                                          {/* 当组件数量大于1时，显示删除按钮 */}
                                          {orgInfo.length > 1 && (
                                              <Button type='text' icon={<MinusCircleOutlined />} onClick={() => remove(index)}></Button>
                                          )}
                                      </div>
                                  </div>
                              ))
                          }
                      </Form.Item>
                      <Form.Item label="入职日期" name="em_commencement_date" rules={[{required: true, message: '请选择入职日期！ '}]}>
                          <DatePicker style={{width: "100%"}}/>
                      </Form.Item>
                      <Form.Item label="工号" name="em_num" rules={[{required: true, message: ' 请输入工号！'}]}>
                          <Input/>
                      </Form.Item>
                      <Form.Item label="员工类型" name="em_type" rules={[{ required: true, message: ' 请选择员工类型！' }]}>
                          <ComboBox data={{1:"普通职员",2:"主管",3:"经理",4:"高层"}}></ComboBox>
                      </Form.Item>

                  </Skeleton>
                </div>
              </Col>
              <Col {..._colSpan}>
                <div className='_col_cnt'>
                  <Skeleton loading={loading} active >
                    <Form.Item label="在职状态" name="em_status" rules={[{ required: true, message: '请选择在职状态！ ' }]}>
                      <Select>
                        <Option value="9">在职中</Option>
                        <Option value="2">停职中</Option>
                        <Option value="1" style={{color:"red"}}>已离职</Option>
                      </Select>
                    </Form.Item>
                      <Form.Item name="em_date_left" label="离职日期" >
                          <DatePicker style={{width:"100%"}}/>
                      </Form.Item>
                      <Form.Item>
                      </Form.Item>
                      <Form.Item></Form.Item>
                  </Skeleton>
                </div>
              </Col>
            </Row>
            {hasPop?null:<>
              <Divider />
              <Row className='_buttons' wrappercol={{ offset: 20, span: 4 }}>
                <Button type="primary" htmlType="submit" disabled={submitting||loading}>Submit</Button>
              </Row>
            </>
            }
          </Form>
          {/*
          <Drawer 
            title={props.title}  contentWrapperStyle={{minWidth:"200px"}} width={isM?"80%":"50%"} placement="right"
            onClose={()=>setUserSelectorVisible(false)}
            visible={userSelectorVisible}
          >
            <Selector serviceUrl={Helper.serviceUrl('user/get_list')} />
          </Drawer>
          */}
        </>
    }

    
    

    if(hasPop){

        return <Drawer  title={props.title}  contentWrapperStyle={{minWidth:"300px"}}
                width={isM?"100%":"50%"} placement="right"    
                {...props.drawerCfg}
                footer={
                    <Space>
                        <Button onClick={onClosePop}>关闭</Button>
                        <Button type="primary" htmlType="submit" onClick={()=>{form.submit();}} disabled={submitting||loading}>Submit</Button>
                    </Space>
                }
            >
            {formx()}
            </Drawer>
    }else{
        return formx();
    }
}
export default EmployeeEditor